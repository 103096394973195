import React from "react"
import { Link } from "gatsby";

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <div className="not-found">
      <h1>404</h1>
      <p>Sorry, this page does not exist :(</p>
      <Link to="/" className="btn btn-outline-primary">← Go back to homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
